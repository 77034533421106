@import './variables.scss';
@import './mixins';

.aapp-modal {
  > .modal-container {
    margin: 30px auto;

    > .modal-content {
      box-shadow: 0 5px 15px $modal-box-shadow-color;
      border-radius: 9px;

      .aapp-modal-form {
        margin: 15px 0;
      }
      > .modal-header {
        @include header(center, true);
        background-color: #fff;
        color: #57c4f8;
        border-radius: 8px 8px 0 0;
      }
      .close {
        outline: none;
        font-size: 26px;
      }
      .close > span {
        color: black;
      }
      .modal-footer {
        display: flex;
        justify-content: center;
        background-color: #fff;
        border-radius: 0 0 8px 8px;
      }
      .modal-footer,
      .btns-section {
        button.btn:not(.aapp-btn-arrow):not(.close) {
          border-radius: 2px;
          min-width: 80px;
          padding: 13px 19px;
          font-size: 13px;
          text-transform: uppercase;
          outline: none;
          border: none;
          color: #fff;

          &:not([disabled]):hover {
            -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.75);
            box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.75);
            -webkit-transition: box-shadow 0.3s;
            transition: box-shadow 0.3s;
          }
          &.btn-primary {
            background-color: #00adf3;
          }
          &.btn-secondary {
            background-color: #636f7b;
          }
        }
      }
    }

    &.warning-modal {
      .modal-content h4 {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  > .modal-md {
    max-width: 600px;
  }

  > .modal-lg {
    max-width: 900px;
  }
}

// use in sales-stories-inputs.component and manage-version.component
.container-confirm {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.12);

  .content {
    @include flex-container($flex-direction: column);
    position: absolute;
    width: 535px;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0 0 20px 5px #9ba8b4b3;
  }

  .confirm-header {
    @include flex-container($justify-content: space-between, $align-items: center);
    padding: 20px;

    h2 {
      margin: 0;
    }

    .btn-confirm-close {
      @include flex-container($align-items: center);
      background-color: transparent;
      border: none;
      outline: none;
      &:focus,
      &:hover {

      }

      .btn-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #E1E1E1;
        margin-right: 4px;
      }
    }
  }

  .confirm-body {
    padding: 0 20px;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }

  .confirm-footer {
    padding: 20px;

    .bnt-ok {
      margin-right: 12px;
    }
  }
}

ac-modal {
  z-index: 1006 !important;
}
