@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

%hover-item {
  background: #0ac15e;
  color: #ffffff;
  cursor: pointer;
}

.icon-collapse-highlight {
  content: '';
  animation: headingToggleAppearing 0.55s linear;
  width: 3em;
  height: 3em;
  position: absolute;
  left: -0.2em;
  top: -0.4em;
  border-radius: 50%;
}

.drag-icon {
  margin: 2px 7px 0px -5px;

  .fa-ellipsis-v {
    width: 0;

    &:first-child {
      left: 10px;
    }
  }
}

.version-selector {
  @include for-screen-size('Small') {
    width: 100%;
    margin-left: 25px;
  }
}

.version-details {
  @include for-screen-size('Small') {
    display: none !important;
  }
}

.mobile-version-details {
  @include for-screen-size('Small') {
    display: block !important;
    font-size: 16px;
    font-weight: 400;
    color: #00a7f7;
  }
}

.change-version-btn .icon {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.label-date {
  padding: 2px 6px;
  border-radius: 24px;
  border: 1px solid #00c872;
  font-weight: 400;
  font-size: 12px;
}

@keyframes fadeSidebarMenu {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes appearSidebarHeading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes headingToggleAppearing {
  from {
    transform: scale(1);
    background-color: darken(rgba(237, 241, 249, 0.5), 5%);
  }
  to {
    transform: scale(0.9);
    background-color: transparent;
  }
}

.side-bar-item-text {
  display: inline-block;
  width: calc(100% - 67px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.side-bar-item.gu-mirror {
  cursor: move;
  background-color: #cfcfcf;
  list-style: none;
  height: 40px;
  padding: 10px 25px;

  &.product-item {
    @include flex-container($align-items: center);

    > .nav-icon {
      @include bars-icon-mixin(12px, 1px, #000);
      display: inline-block;
      position: unset;
      margin-top: 0;
      margin-right: 8px;
      left: 0;
      top: -9px;

      .icon-bar:first-child {
        margin-top: 0;
      }
    }

    > .nav-content {
      @include flex-container(
        $flex-direction: column,
        $align-items: flex-start
      );
      width: 100%;

      .product-note {
        width: 100%;
      }

      .side-bar-item-text {
        font-weight: 600;
      }

      .side-bar-add-note-btn {
        width: 100%;
        height: 24px;
        text-align: start;
        color: $text-secondary-light-gray;
        border: none;
        background-color: transparent;
        padding: 0;
      }

      .edit-pencil-icon {
        visibility: hidden;
      }

      .input-container {
        display: flex;
        width: 100%;

        .form-input {
          height: 24px;
          border-radius: 2px;
          border-color: #fff;
          border: #fff solid 1px;
          width: 100%;
          margin-right: 5px;

          &.save-note-mode {
            width: calc(100% - 60px);
          }

          &::placeholder {
            color: $text-secondary-light-gray;
          }
        }
      }
    }
  }

  > ensight-simple-toggle {
    display: none;
  }
}

.side-bar .nav-wrapper .plans-nav .list-unstyled .side-bar-item .nav-icon {
  position: unset ;

  .icon-bar:first-child {
    margin-top: 0 ;
  }
}

.disabled {
  .side-bar {
    .side-bar-item {
      cursor: wait !important;
    }
  }
}

.side-bar {
  position: fixed;
  z-index: 1000;
  height: calc(100% - $presentation-header-height);
  max-width: 24.5vw;
  background: $side-bar-color;
  padding: 15px 0px;
  border-right: 1px solid #d9d9d9;
  transition: margin-left 1s ease, top 0.7s ease-out, height 0.7s ease-out;

  .nav-wrapper {
    overflow-y: auto;
    max-height: 102%;

    .plans-nav,
    .pages-nav {
      .list-unstyled .nav-icon {
        @include bars-icon-mixin(12px, 1px);
        display: inline-block;
        margin-top: 0;
        margin-right: 8px;
        left: 0;
        top: -9px;
      }
    }
    .plans-nav {
      .side-bar-item-text {
        width: calc(100% - 25px);

        &.product {
          margin-left: 30px;
        }
      }
    }
  }
  .pages-nav,
  .plans-controls {
    margin-top: 30px;
  }

  .plans-controls {
    .btn {
      display: flex;
      align-items: center;

      > .fa-plus {
        font-size: 1.8rem;
        color: $light-link-color;
        margin-right: 10px;
      }
    }
  }

  &.collapsed {
    transition: margin-left 1s ease;
    margin-left: -25%;
    .collapse-btn {
      transition: right 0.5s ease;
      right: -15px;
      z-index: 999;
    }
  }

  .item-main {
    padding: 10px 25px;
  }
  .item-main {
    position: relative;
    &:hover:not(.input-focus) {
      @extend %hover-item;
    }
  }
  .custom-main-color,
  .side-bar-item > section {
    background-color: $bg-light-gray;
  }
  .side-bar-item {

    &.selected {
      color: #ffffff;

      .side-bar-add-note-btn {
        color: #ffffff !important;
      }

      .edit-pencil-icon {
        filter: invert(85%) brightness(2) contrast(1.2);
      }

      > section {
        color: #333;
        background-color: #dfefe4;
      }
      .custom-main-color {
        box-shadow: none;
      }
    }

    > section {
      &:first-of-type {
        padding-top: 15px;
      }
      &:last-child {
        padding-bottom: 15px;
      }

      padding: 5px 25px;
      cursor: pointer;
      @include before-hover();
      > span {
        margin-left: 20px;
      }
    }

    .custom-main-color {
      box-shadow: 0 2px 4px 0 $sidebar-active-color;
    }

    .ensight-icon-document {
      font-size: 20px;
      /* temporary workaround related to bed symbol in icon font*/
      display: block;
      margin: -2px 4px 0 0;
      float: left;
      /* temporary workaround related to bed symbol in icon font*/
    }
  }
  .pr-plans {
    .plan-item {
      @include flex-container($align-items: center);

      .drag-icon {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }

      .nav-content {
        @include flex-container(
          $flex-direction: column,
          $align-items: flex-start
        );
        width: 100%;
      }

      .side-bar-item-text {
        font-weight: 600;
      }

      .product-note {
        width: 100%;
      }
    }
  }

  .collapse-btn {
    display: inline-block;
    padding: 5px 3px;
    background-color: white;
    border-radius: 5px;
    vertical-align: middle;
    border: solid 1px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(50%, -50%);
    transition: right 0.5s ease;
    z-index: 999;
    &:hover {
      cursor: pointer;
    }
    .fa {
      margin-top: 3px;
      color: $link-color;
    }
  }

  .divider {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .nav-padding {
    padding: 0px 25px;
    > li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ul[dnd-list] {
    position: relative;

    > li {
      position: relative;
    }

    .dndDraggingSource {
      display: none;
    }

    .dndPlaceholder {
      display: block;
      background-color: #ddd;
      min-height: 42px;
    }
  }

  .progress {
    line-height: 15px;
    display: inline-block;
    width: 100px;
    border: 3px groove #ccc;
  }

  .progress div {
    font-size: smaller;
    background: orange;
    width: 0;
  }
}

.nav-divider {
  height: 60px;
  width: 1px;
  background-color: $light-gray;
  margin-left: 5px;
}

::ng-deep se-button .save-note {
  height: 24px !important;
  width: 60px;
  background-color: #fff !important;
}

.sidebar-heading-content {
  @include collapsed-header;
}

.sidebar-heading-content {
  @include flex-container(
    $justify-content: space-between,
    $align-items: center
  );
  position: fixed;
  width: 100%;
  height: $preview-navbar-height;
  z-index: 1005;
  box-shadow: 0 4px 4px rgba(171, 171, 171, 0.25);

  .logo-block {
    @include flex-container($align-items: center);
    width: 25%;

    > .heading-toggle {
      @include bars-icon-mixin(26px, 3px, #999);
      margin-top: 0;
      > .icon-bar {
        margin-top: 6px;
      }
      margin-bottom: 4px;
    }

    > .a-app-view-logo {
      padding: 19px 29px;
      margin-left: 35px;
      margin-top: 0px;
      display: inline-block;
      vertical-align: middle;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      -webkit-background-size: contain;
      -webkit-background-repeat: no-repeat;
      -webkit-background-position: center;
      background-image: $app-logo-img-black;
    }

    > .aligned-logo {
      margin-left: 5px;
    }
  }

  .navigation-section {
    :nth-child(1) {
      margin-right: 6px;
    }

    :nth-child(2) {
      margin-left: 6px;
    }
  }

  .action-btn-block {
    @include flex-container($align-items: center);

    > div {
      display: flex;
    }

    & > * {
      text-decoration: none;
    }

    .btn-fullscreen {
      padding-left: 6px;
    }
  }

  .btn-close {
    font-size: 2rem;
    font-weight: 700;
    padding: 0.375rem 1.06rem;
  }

  .btn-close:focus {
    outline: none;
  }

  .btn-icon {
    display: inline-block;
    position: initial;
    width: 20px;
    height: 20px;
    background-position: center;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-right: 3px;

    &.share-link {
      margin-right: 5px;
    }
  }

  .next-icon.disabled {
    background-image: url('/assets/icons/next-disabled.svg');
  }

  .back-icon.disabled {
    background-image: url('/assets/icons/back-disabled.svg');
  }

  .settings-icon {
    background-image: url('/assets/icons/settings.svg');
  }

  .fullscreen-icon {
    @extend %fullscreen-icon;
    //background-image: url('/assets/icons/fullscreen.svg');
  }

  .exit-fullscreen-icon {
    @extend %fullscreen-icon;
    //background-image: url('/assets/icons/fullscreen_exit.svg');
  }

  .next-icon {
    @extend %arrow-icon;
    background-image: url('/assets/icons/next.svg');
  }

  .back-icon {
    @extend %arrow-icon;
    background-image: url('/assets/icons/back.svg');
  }

  %arrow-icon {
    width: 24px;
    height: 22px;
    margin-bottom: 2px;
    margin-left: 5px;
    margin-right: 5px;
  }

  %fullscreen-icon {
    font-size: 18px;
    margin: 0 3px 0 0;
  }

  .download-icon {
    background-image: url('/assets/icons/download.svg');
  }
}

.preview-sidebar {
  position: fixed;
  z-index: 1002;
  height: 100%;
  background-color: #85a9d0;
  padding: 0;
  border-right: 0;
  transition: margin-left 0.7s ease, opacity 0.7s ease;

  &.collapsed {
    transition: margin-left 0.7s ease, opacity 0.7s ease;
    margin-left: -25%;
    opacity: 0;
  }

  & > .sidebar-heading {
    height: 5em;
    background-color: #fff;
  }

  & > .sidebar-content {
    height: 94%;
    color: #fffffa;
    overflow-y: auto;
    margin-top: 60px;

    .header-sidebar {
      margin: 1.5em 0.5em 1.5em 1.5em;
      @include flex-container(
        $justify-content: space-between,
        $align-items: center
      );

      h5 {
        animation: appearSidebarHeading 0.25s linear;
        text-transform: uppercase;
        letter-spacing: 0.3em;
        margin: 0;
      }

      .toggle-sidebar {
        color: white;
        font-weight: bold;
        font-size: 22px;
      }
    }

    > ul.list-unstyled {
      font-size: 1.1em;
      letter-spacing: 0.1em;
      color: #ccdaea;

      > li {
        cursor: pointer;
        animation: fadeSidebarMenu 0.6s cubic-bezier(0.65, 0.05, 0.36, 1);
        animation-fill-mode: both;

        @for $i from 1 to 15 {
          &:nth-child(#{$i}) {
            animation-delay: $i * 0.12s;
          }
        }

        &.selected {
          > .item-main {
            background-color: rgba(255, 255, 255, 0.15);
            transition: 350ms background-color ease;
          }
          > section,
          > .item-main {
            color: #ebfdfb;
          }
        }

        > .item-main > div,
        > section > span {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 75%;
          white-space: nowrap;
          margin-left: 3em;
        }

        > .item-main {
          > div {
            transition: 210ms transform linear;
            padding: 1.5em 0.5em;
          }

          &:hover > div {
            color: #ebfdfb;
            transform: translateX(1em);
          }
        }

        > section {
          @include before-hover(#ebfdfb, 7px);

          > span {
            padding: 0.65em 0.5em;
          }
          &:hover {
            color: #ebfdfb;
          }
          &:first-child {
            padding-top: 17px;
          }
          &:last-child {
            padding-bottom: 17px;
          }
        }
      }
    }
  }
}

.static-page-item {
  > div {
    padding: 0 25px;
  }

  > .static-page-content {
    @include flex-container($align-items: center);
    padding: 10px 25px;
    margin: 10px 0;

    &.selected,
    &:hover {
      @extend %hover-item;
    }

    > .icon {
      font-size: 14px;
      color: #c5c7c9;
      margin-right: 8px;
    }

    > .page-name {
      @extend .side-bar-item-text;
    }
  }

  > .static-page-message {
    font-size: 14px;
    color: #ff7c00;
  }

  &:before,
  &:after {
    position: relative;
    display: block;
    margin: 5px 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    content: '';
    border-top: 1px solid $light-gray;
  }

  &:after {
    content: '';
    border-bottom: 1px solid $light-gray;
  }
}

.disabledNav {
  pointer-events: none;
}

@media screen and (max-height: 800px) {
  .preview-sidebar {
    & > .sidebar-content {
      height: 92%;
    }
  }
}

.spinner-holder {
  @include spinner-holder();
}

:host-context(.shared-presentation) {
  .nav-divider {
    @include for-screen-size('customSmall') {
      display: none;
    }
  }

  .sidebar-heading-content {
    .logo-block {
      width: initial;

      > .a-app-view-logo {
        @include for-screen-size('customSmall') {
          display: none;
        }
      }
    }

    .navigation-section {
      @include for-screen-size('Small') {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: white;
        z-index: 999;
        padding: 12px 0;
        @include flex-container($justify-content: center, $align-items: center);
      }

      .btn-text {
        @include for-screen-size('Medium') {
          display: none;
        }
      }
    }

    .action-btn-block {
      .empty-container {
        display: none;
      }

      .btn-fullscreen {
        @include for-screen-size('Small') {
          display: none;
        }
      }

      .btn-link {
        .ep-action-message {
          @include for-screen-size('Large') {
            left: -200%;
          }
        }
      }

      .action-text {
        @include for-screen-size('Large') {
          display: none;
        }
      }
    }
  }

  .preview-sidebar {
    @include for-screen-size('Large') {
      width: 444px;
      max-width: 444px;

      &.collapsed {
        margin-left: -446px;
      }
    }

    @include for-screen-size('Small') {
      width: 100%;
      max-width: 100%;
      z-index: 1000;

      &.collapsed {
        margin-left: -768px;
      }
    }

    & > .sidebar-content {
      margin-top: 48px;

      @include for-screen-size('Small') {
        margin-top: 0;
      }

      > ul.list-unstyled {
        > li {
          > .item-main > div,
          > section > span {
            margin-left: 1em;
            text-overflow: initial;
            overflow: initial;
            max-width: 90%;
            white-space: initial;
          }

          > .item-main {
            > div {
              padding: 1em 0.5em;
            }
          }

          .item-custom {
            .side-bar-item-text {
              display: flex;
            }
          }
        }
      }
    }
  }
}

.btn-menu {
  width: 24px;
  height: 24px;
  //background-position: center;
  //background-image: url('../../../../../assets/icons/kebab-menu-shared.svg');
  //background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  background-color: transparent;

  span {
    font-size: 22px;
  }
}

.menu.shared-presentation-actions {
  position: absolute;
  min-width: 200px;
  right: 4px;
  top: 46px;
  border: 1px solid $border-base;
  border-radius: 4px;
  box-shadow: 0px 2px 2px 0px $light-gray;

  .btn-menu-item {
    padding: 6px 10px;
    background-color: $white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: $font-color-base;
    cursor: pointer;

    img {
      width: 16px;
    }
  }
}

.rotate-screen-suggestion {
  display: none;

  @include for-screen-size('customSmall') {
    position: fixed;
    top: 60px;
    height: 100%;
    padding-bottom: 120px;
    width: 100%;
    background: rgba(255, 255, 255, 0.87);
    @include flex-container(
      $flex-direction: column,
      $justify-content: center,
      $align-items: center
    );

    .rotate-device-icon {
      width: 92px;
      height: 80px;
      margin-bottom: 20px;
    }

    .rotate-hint-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 32px;
    }

    .dismiss-btn {
      @include flex-container($justify-content: center, $align-items: center);
      width: 156px;
      height: 44px;
      border-radius: 8px;
      border: 1px solid $metric-primary-color;
      background-color: $metric-primary-color;
      font-size: 16px;
      color: $white;
    }
  }
}

.a-app-action-buttons-color {
  ::ng-deep {
    * {
      color: inherit !important;
    }
  }

  * {
    color: inherit !important;
  }
}

.a-app-menu-items-color:not(.selected) ~ .a-app-sub-menu-items-color {
  background-color: #f7f7f7 !important;

  &:hover {
    background-color: inherit !important;
  }
}
