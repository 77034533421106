//$print-page-size: auto;
//$fa-font-path: '~font-awesome/fonts';
@import './theme-variables';
@import 'dragula/dist/dragula.min.css';
@import 'font-awesome/css/font-awesome.css';
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'variables';
@import 'bootstrap/scss/bootstrap';
@import './../tiny/load-fonts.css';

@import 'icons-classes';
@import 'print';
@import 'grid';
@import 'action-button-link';
@import 'setup';
@import 'view';
@import 'style-editor';
@import 'details-plans.scss';
@import 'modal.scss';
@import 'retirement-social-security';
@import 'inheritances';
@import 'font-overrides';
@import 'shared';
@import 'form-controls';
@import 'codemirror/lib/codemirror';
@import 'codemirror/addon/hint/show-hint.css';
@import 'codemirror/addon/lint/lint.css';
@import 'codemirror/addon/scroll/simplescrollbars.css';
@import 'codemirror/lib/codemirror';
@import 'codemirror/theme/material';
@import 'codemirror/addon/merge/merge.css';
@import 'codemirror/addon/dialog/dialog.css';
@import 'select';
@import 'date-picker';
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.CodeMirror-hints,
.CodeMirror-hint,
li.CodeMirror-hint-active,
.CodeMirror-lint-tooltip {
  z-index: 9999;
}

* {
  -ms-overflow-style: scrollbar;
}

html {
  min-height: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  font-size: 14px;
  line-height: 1.4;
}

body {
  font-family: 'Museo Sans';
  font-weight: 300;
}

// Overwrite default bootstrap _reboot.scss 'bolder' style for 'strong' and 'b' tags
b,
strong {
  font-weight: bold;
}

[hidden] {
  display: none !important;
}

.ng-cloak {
  display: none;
}

.checked-item {
  background-color: white;
  border-radius: 50%;
  border: 5px solid $light-link-color;
  display: inline-block;
  width: 18px;
  height: 18px;
  top: 2px;
  position: relative;
}

.unchecked-item {
  background-color: white;
  border-radius: 50%;
  border: 1px solid grey;
  display: inline-block;
  width: 18px;
  height: 18px;
  top: 2px;
  position: relative;
}

.aapp-btn {
  border-radius: 0;
  margin: 0 5px;

  &.aapp-btn-default {
    background-color: $aapp-btn-default-background-color;
    color: #ffffff;

    &:hover {
      background-color: darken($aapp-btn-default-background-color, 10%);
    }
  }
}

.btn-label {
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom-color: $link-color;
  }
}

.ng-select .select2-container--default .select2-selection {
  display: block;
  width: 100%;
  font-size: 14px;
  min-height: 32px;
  line-height: 1.42857143;
  color: #555;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

agency-connections-input.ng-invalid.ng-dirty
  .ng-select
  .select2-container--default
  .select2-selection {
  border: 1px solid #ff0000;
}

section.aapp-main-container {
  height: calc(100% - 185px);
}

label.required:after {
  @include required(6px, red);
}

.error {
  color: red;
}

.disablePointerEvents {
  cursor: wait;

  > * {
    pointer-events: none;
  }
}

.spinner-circle {
  margin: 4em auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner-circle > div {
  background-color: #006bb6;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner-circle .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner-circle .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner-circle .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner-circle .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.loader {
  position: relative;

  &::after,
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &::after {
    margin: auto;
    border: 16px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    border-top: 16px solid rgba(255, 255, 255, 0.5);
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 10001;
  }

  &::before {
    display: block;
    background-color: #656e7b;
    z-index: 998;
    opacity: 0.5;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// START spinner for the buttons

$loadingSpinnerDiameter: 28px;
$loadingSpinnerStrokeWidth: 4px;
$loadingSpinnerStrokeColor: #fff;
$loadingSpinnerOpacity: 0.8;
$loadingSpinnerStrokeStyle: solid; // solid, dashed, dotted
$loadingSpinnerSpeed: 0.7s;

.loadingSpinner {
  border: $loadingSpinnerStrokeWidth $loadingSpinnerStrokeStyle
    $loadingSpinnerStrokeColor;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  width: $loadingSpinnerDiameter - ($loadingSpinnerStrokeWidth * 2);
  height: $loadingSpinnerDiameter - ($loadingSpinnerStrokeWidth * 2);
  opacity: $loadingSpinnerOpacity;
  border-radius: 50%;
  animation: loadingSpinner $loadingSpinnerSpeed infinite linear;
  -webkit-animation: loadingSpinner $loadingSpinnerSpeed infinite linear;
}

button .loadingSpinner {
  margin-top: calc(-1 * $loadingSpinnerDiameter / 2);
  margin-left: calc(-1 * $loadingSpinnerDiameter / 2);
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/*
This rule needs to be here, it applies to plans-grid component
*/

// END spinner for the buttons

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.dropdown-toggle::after {
  content: none;
}

.hidden,
.hide {
  display: none;
}
